import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'country',
    'invoiceType'
  ]

  connect() {
    this.showHideInvoiceType()
  }

  showHideInvoiceType() {
    if (this.countryTarget.value === 'TR') {
      this.invoiceTypeTarget.classList.remove('hidden')
    } else {
      this.invoiceTypeTarget.classList.add('hidden')
    }
  }
}
