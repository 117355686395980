import {Controller} from "@hotwired/stimulus"
import debounce from "debounce"

export default class extends Controller {
  static targets = ['table', 'pagination', 'spinner', 'query', 'filterButton', 'countrySelect', 'currencySelect', 'sortLink']
  static values = {url: String, refreshInterval: Number}

  initialize() {
    let options = {rootMargin: '200px'}
    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
    this.refresh = debounce(this.refresh.bind(this), 500)
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
    if (this.hasRefreshIntervalValue) this.startRefreshing()

    const urlSearchParams = new URLSearchParams(window.location.search);
    const currentParams = Object.fromEntries(urlSearchParams.entries());
    const isTriggered = currentParams.triggered === 'true'
    if (isTriggered) {
      this.currentPage = 1
      this.filterButtonTargets.find((btn) => btn.dataset.status === currentParams.status).click()
    } else {
      this.resetParams()
    }
  }

  disconnect() {
    this.stopRefreshing()
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) this.loadMore()
    })
  }

  loadMore() {
    this.currentPage += 1
    let params = this.adjustParams()
    params.set('inline', true)

    let url = `${this.urlValue}?${params.toString()}`

    fetch(url)
        .then(response => response.text())
        .then((html) => {
          this.spinnerTarget.classList.add('hidden')
          this.tableTarget.insertAdjacentHTML('beforeend', html)
        })
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => this.refresh(), this.refreshIntervalValue)
  }

  stopRefreshing() {
    if (this.refreshTimer) clearInterval(this.refreshTimer)
  }

  filterByStatus(event) {
    this.currentStatus = event.currentTarget.dataset.status

    // Set up for button states
    this.filterButtonTargets.forEach(button => button.classList.remove('filter-active'))
    event.currentTarget.classList.add('filter-active')

    this.refresh()
    return event.preventDefault()
  }

  filterByStatusViaLink(event) {
    this.filterButtonTargets.find((btn) => btn.dataset.status === 'under_validation').click()
    return event.preventDefault()
  }

  filterByCountry(event) {
    this.currentCountry = this.countrySelectTarget.value
    this.refresh()
    return event.preventDefault()
  }

  filterByCurrency(event) {
    this.currentCurrency = this.currencySelectTarget.value
    this.refresh()
    return event.preventDefault()
  }

  sortBy(event) {
    if (this.currentSortColumn === event.currentTarget.dataset.order) {
      this.currentSortType = this.currentSortType === 'asc' ? 'desc' : 'asc'
    } else {
      this.currentSortType = 'asc'
    }
    this.currentSortColumn = event.currentTarget.dataset.order
    this.sortLinkTargets.forEach((link) => {
      link.classList.remove('sort-asc', 'sort-desc')
      if (link.dataset.order === this.currentSortColumn)
        link.classList.add(`sort-${this.currentSortType}`)
    })
    this.refresh()
    return event.preventDefault()
  }

  resetParams() {
    this.currentPage = 2
    this.currentStatus = null
    this.currentCountry = null
    this.currentCurrency = null
    this.currentSortColumn = 'created_at'
    this.currentSortType = 'desc'
    this.refresh()
  }

  reset(event) {
    if (this.hasCountrySelectTarget) this.countrySelectTarget.slim.setSelected(0)
    if (this.hasCurrencySelectTarget) this.currencySelectTarget.slim.setSelected(0)
    if (this.hasQueryTarget) this.queryTarget.value = ''
    if (this.hasFilterButtonTarget)
      this.filterButtonTargets.forEach(button => button.classList.remove('filter-active'))
    if (this.hasSortLinkTarget)
      this.sortLinkTargets.forEach((link) => link.classList.remove('sort-asc', 'sort-desc'))
    this.resetParams()
    return event.preventDefault()
  }

  refresh() {
    let params = this.adjustParams()
    params.set('full', true)
    let url = `${this.urlValue}?${params.toString()}`

    fetch(url)
        .then(response => response.text())
        .then(html => this.tableTarget.innerHTML = html)
  }

  adjustParams() {
    let params = new URLSearchParams()
    if (this.hasQueryTarget) params.set('query', this.queryTarget.value)
    params.set('page', this.currentPage)
    if (this.currentStatus) params.set('status', this.currentStatus)
    if (this.currentCountry) params.set('country', this.currentCountry)
    if (this.currentCurrency) params.set('currency', this.currentCurrency)
    if (this.currentSortColumn) {
      params.set('sort', this.currentSortColumn)
      params.set('sorttype', this.currentSortType)
    }

    return params
  }

  countryNameShow(event) {
    event.target.querySelector('span:first-child').classList.add('lg:hidden')
    event.target.querySelector('span:last-child').classList.remove('lg:hidden')
  }

  countryNameHide(event) {
    event.target.querySelector('span:first-child').classList.remove('lg:hidden')
    event.target.querySelector('span:last-child').classList.add('lg:hidden')
  }
}
