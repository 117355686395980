import CountryBaseController from "@/controllers/country_base_controller";

export default class extends CountryBaseController {
  static targets = [
    // Components
    'selectBox', 'ajaxified',
    // Country/Ports Population Targets
    'pol',
    // Association Options
    'vessel', 'city',
    // Person Relations
    'companyCity', 'companyCountry',
    'shipperName',
    'consigneeName',
  ]

  connect() {
    this.base_country = this.element.dataset.country
    if (this.hasSelectBoxTarget)
      this.selectBoxTargets.forEach(element => this.makeSlimSelect(element))
    if (this.hasAjaxifiedTarget) {
      let request_url = `/${this.base_country}/api/`
      this.ajaxifiedTargets.forEach(element => {
        if (element === this.vesselTarget) {
          let url = `${request_url}vessels?`
          this.makeAjaxifiedSelect(element, url, 'vessel')
        } else if (this.cityTargets.includes(element)) {
          let url = `${request_url}cities?`
          this.makeAjaxifiedSelect(element, url, 'city')
        }
      })
    }
  }

  populate(event) {
    const selectBox = event.target,
        target = this[selectBox.dataset.populatetarget]

    target.slim.enable()
    target.setAttribute('data-countryid', selectBox.value)

    if (target.dataset.afterpopulatecityid) {
      target.slim.setData([{text: target.dataset.afterpopulatecityname, value: target.dataset.afterpopulatecityid}])
      target.slim.setSelected(target.dataset.afterpopulatecityid)
      target.removeAttribute('data-afterpopulatecityname')
      target.removeAttribute('data-afterpopulatecityid')
    } else if (target.dataset.entitytype !== 'consignees') {
      target.slim.setData([{text: '', placeholder: true}])
      target.slim.setSelected(0)
    }
  }
}
