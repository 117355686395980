import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['vehicleAge', 'related']

  connect() {
    if (this.hasVehicleAgeTarget) {
      this.toggle()
    }
  }

  toggle() {
    if (this.vehicleAgeTarget.value === ' Brand new' || this.vehicleAgeTarget.value === '')
      this.relatedTargets.forEach(target => target.classList.add('hidden'))
    else
      this.relatedTargets.forEach(target => target.classList.remove('hidden'))
  }
}
