import {Application} from "@hotwired/stimulus"
import {registerControllers} from 'stimulus-vite-helpers'
import {Alert, Dropdown, Modal, Tabs} from "tailwindcss-stimulus-components"

const application = Application.start()
// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// Controller files must be named *_controller.js.
const controllers  = import.meta.globEager('./**/*_controller.js')
registerControllers(application, controllers)

const components  = import.meta.globEager('../../components/**/*.js')
registerControllers(application, components)

application.register('alert', Alert)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('dropdown', Dropdown)

