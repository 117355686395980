import {Controller} from "@hotwired/stimulus"

export default class PassiveUser extends Controller {
  static formInputs = [
    'label',
    'name',
    'email',
    'address',
    'country',
    'billable',
    'usdAccountCode',
    'eurAccountCode',
    'aedAccountCode',
    'vatNumber',
    'agency',
    'currency',
    'eInvoice',
    'taxOffice',
    'interest',
    'modifier',
  ]

  static targets = [
    'userSelect',
    'createButton',
    'cancelButton',
    'createForm',
    'trOptionalFields',
    ...this.formInputs,
  ]

  static values = {country: String}

  connect() {
    if (this.userSelectTarget.dataset.existed === 'true') {
      this.passiveUserChanged()
    } else {
      this.userSelectTarget.value = ''
      this.userSelectTarget.setAttribute('disabled', 'disabled')
      this.toggleOptionalFields()
    }
  }

  passiveUserChanged() {
    const userId = this.userSelectTarget.value
    if (userId === '') return

    this.showForm()

    fetch(`/passive_users/${userId}.json`)
        .then(response => response.json())
        .then((json) => {
          this.enableFormInputs()
          this.populateFormInputs(json)
          this.toggleOptionalFields()
          this.disableFormInputs()
        })
        .catch(() => {
          console.log("Couldn't find user")
        })
  }

  createNewPassiveUser(event) {
    this.clearUserSelect();
    this.adjustButtonsForUserCreation();
    this.showForm()
    this.enableFormInputs()
    if (this.createButtonTarget.innerText === 'CREATE NEW USER') {
      this.clearFormInputs();
    }
    this.toggleOptionalFields()
    return event.preventDefault()
  }

  cancelCreateNewPassiveUser(event) {
    this.userSelectTarget.slim.enable()
    this.adjustButtonsForUserSelection();
    this.hideForm()
    return event.preventDefault()
  }

  toggleOptionalFields() {
    if (this.countryTarget.value === 'TR') {
      this.trOptionalFieldsTarget.classList.remove('hidden')
    } else {
      this.trOptionalFieldsTarget.classList.add('hidden')
    }
  }

  adjustButtonsForUserCreation() {
    this.disableButton('createButton')
    this.showElement('cancelButton')
  }

  clearUserSelect() {
    this.userSelectTarget.slim.setSelected('')
    this.userSelectTarget.slim.disable()
  }

  adjustButtonsForUserSelection() {
    this.enableButton('createButton')
    this.hideElement('cancelButton')
  }

  showForm() {
    this.showElement('createForm')
  }

  hideForm() {
    this.hideElement('createForm')
  }

  enableFormInputs() {
    // Radio buttons are plural, so enable all of them
    this.agencyTargets.forEach(elem => {
      elem.removeAttribute('disabled')
      elem.classList.remove('opacity-50')
    })

    this.eInvoiceTargets.forEach(elem => {
      elem.removeAttribute('disabled')
      elem.classList.remove('opacity-50')
    })

    this.currencyTargets.forEach(elem => {
      elem.removeAttribute('disabled')
      elem.classList.remove('opacity-50')
    })

    this.billableTargets.forEach(elem => {
      elem.removeAttribute('disabled')
      elem.classList.remove('opacity-50')
    })

    PassiveUser.formInputs.forEach(input => {
      const element = this[`${input}Target`]
      element.removeAttribute('disabled')
      element.classList.remove('opacity-50')
    })
  }

  clearFormInputs() {
    PassiveUser.formInputs.forEach(input => {
      const element = this[`${input}Target`]
      this.clearFormInput(element);
    })

    this.agencyTargets.forEach(element => {
      this.clearFormInput(element);
    })

    this.eInvoiceTargets.forEach(element => {
      this.clearFormInput(element);
    })

    this.billableTargets.forEach(element => {
      this.clearFormInput(element);
    })
  }

  disableFormInputs() {
    // Disable radio buttons
    document
        .querySelectorAll(`input[name='${this.countryValue}_entry[passive_user_attributes][agency]']`)
        .forEach(radio => {
          radio.setAttribute('disabled', 'disabled')
          radio.classList.add('opacity-50')
        })
    document
        .querySelectorAll(`input[name='${this.countryValue}_entry[passive_user_attributes][e_invoice]']`)
        .forEach(radio => {
          radio.setAttribute('disabled', 'disabled')
          radio.classList.add('opacity-50')
        })
    document
        .querySelectorAll(`input[name='${this.countryValue}_entry[passive_user_attributes][currency]']`)
        .forEach(radio => {
          radio.setAttribute('disabled', 'disabled')
          radio.classList.add('opacity-50')
        })
    document
        .querySelectorAll(`input[name='${this.countryValue}_entry[passive_user_attributes][billable]']`)
        .forEach(radio => {
          radio.setAttribute('disabled', 'disabled')
          radio.classList.add('opacity-50')
        })

    PassiveUser.formInputs.forEach(input => {
      this[`${input}Target`].setAttribute('disabled', 'disabled')
      this[`${input}Target`].classList.add('opacity-50')
    })
  }

  populateFormInputs({data: {attributes: person}}) {
    this.labelTarget.value = person.label
    this.nameTarget.value = person.name
    this.emailTarget.value = person.email
    this.addressTarget.value = person.address
    this.countryTarget.value = person.country
    this.checkRadioButton(this.agencyTargets, person.agency)
    this.checkRadioButton(this.billableTargets, person.billable)
    this.usdAccountCodeTarget.value = person['usd-account-code']
    this.eurAccountCodeTarget.value = person['eur-account-code']
    this.aedAccountCodeTarget.value = person['aed-account-code']
    this.vatNumberTarget.value = person['vat-number']
    this.checkRadioButton(this.eInvoiceTargets, person['e-invoice'])
    this.checkRadioButton(this.currencyTargets, person['currency'])
    this.taxOfficeTarget.value = person['tax-office']
    this.interestTarget.value = person['interest-discount-rate']
    this.modifierTarget.value = person['modifier-value']
  }

  clearFormInput(element) {
    element.type === 'radio' ? element.checked = false : element.value = ''
  }

  enableButton(targetName) {
    this[`${targetName}Target`].removeAttribute('disabled')
    this[`${targetName}Target`].classList.remove('disabled:opacity-50', 'opacity-50', 'cursor-not-allowed', 'pointer-events-none')
  }

  disableButton(targetName) {
    this[`${targetName}Target`].classList.add('disabled:opacity-50', 'opacity-50', 'cursor-not-allowed', 'pointer-events-none')
    this[`${targetName}Target`].setAttribute('disabled', 'disabled')
  }

  showElement(targetName) {
    this[`${targetName}Target`].classList.remove('hidden')
  }

  hideElement(targetName) {
    this[`${targetName}Target`].classList.add('hidden')
  }

  checkRadioButton(radios, value) {
    radios.forEach(radio => radio.checked = `${radio.value}` === `${value}`)
  }
}
