import CountryBaseController from "@/controllers/country_base_controller";

export default class extends CountryBaseController {
  static targets = [
    // Components
    'selectBox', 'ajaxified',
    // Country/Ports Population Targets
    'pol',
    // Association Options
    'hsCode',
    // Person Relations
    'shipperPersonType', 'shipperName', 'shipperEmail', 'shipperPhone', 'shipperAddress', 'shipperCountry',
    'consigneePersonId', 'consigneePersonType', 'consigneeName', 'consigneeEmail', 'consigneePhone', 'consigneeAddress', 'consigneeCountry'
  ]

  connect() {
    this.base_country = this.element.dataset.country
    if (this.hasSelectBoxTarget)
      this.selectBoxTargets.forEach(element => this.makeSlimSelect(element))
    if (this.hasAjaxifiedTarget) {
      let request_url = `/${this.base_country}/api/`
      this.ajaxifiedTargets.forEach(element => {
        if (element === this.hsCodeTarget) {
          let url = `${request_url}hs_codes?`
          this.makeAjaxifiedSelect(element, url, 'hsCode')
        }
      })
    }
  }

  async populatePerson(event) {
    const entityType = event.currentTarget.dataset.entitytype
    const entityName = entityType.slice(-3) === 'ies' ? `${entityType.slice(0, -3)}y` : `${entityType.slice(0, -1)}`

    const response = await this.getPersonDataToPopulate(event)

    if (!response.data || response.error) {
      this[`${entityName}PersonTypeTargets`].forEach(radio => radio.removeAttribute('checked'))
      this[`${entityName}EmailTarget`].value = ''
      this[`${entityName}PhoneTarget`].value = ''
      this[`${entityName}AddressTarget`].value = ''
      this[`${entityName}CountryTarget`].slim.setSelected(0)
      if (entityType === 'consignees')
        this[`${entityName}PersonIdTarget`].value = ''
      return
    }

    const {data: person} = response
    const personTypeVal = person['person-type'] === "COMPANY" ? "0" : "1"
    this[`${entityName}PersonTypeTargets`].forEach(radio => {
      if (radio.value === personTypeVal)
        radio.setAttribute('checked', 'checked')
      else
        radio.removeAttribute('checked')
    })
    this[`${entityName}EmailTarget`].value = person.email
    this[`${entityName}PhoneTarget`].value = person.phone
    this[`${entityName}AddressTarget`].value = person.address
    this[`${entityName}CountryTarget`].slim.setSelected(person['country-id'])
    if (entityType === 'consignees')
      this[`${entityName}PersonIdTarget`].value = person['person-id']
  }
}
