import {Controller} from "@hotwired/stimulus"

export default class PassiveUserEditController extends Controller {
  static targets = [
    'country',
    'trMandatoryField',
  ]

  connect() {
    this.showHideTRFields()
  }

  showHideTRFields() {
    if (this.countryTarget.value === 'TR') {
      this.trMandatoryFieldTargets.forEach((field) => {
        field.classList.remove('hidden')
      })

    } else {
      this.trMandatoryFieldTargets.forEach((field) => {
        field.classList.add('hidden')
      })
    }
  }
}
