import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['row', 'actions', 'approvalBox', 'rejectionBox', 'modalApproveButton', 'modalRejectButton', 'modalCloseButton']

  showActions() {
    this.rowTarget.classList.add('hidden')
    this.actionsTarget.classList.remove('hidden')
  }

  hideActions() {
    this.rowTarget.classList.remove('hidden')
    this.actionsTarget.classList.add('hidden')
  }

  modalApproveClicked(event) {
    event.preventDefault();
    this.approvalBoxTarget.classList.remove('hidden')
    this.rejectionBoxTarget.classList.add('hidden')
    this.modalApproveButtonTarget.classList.add('hidden');
    this.modalRejectButtonTarget.classList.add('hidden');
    this.modalCloseButtonTarget.classList.add('hidden');
  }

  modalRejectClicked(event) {
    event.preventDefault();
    this.rejectionBoxTarget.classList.remove('hidden')
    this.modalApproveButtonTarget.classList.add('hidden');
    this.modalRejectButtonTarget.classList.add('hidden');
    this.modalCloseButtonTarget.classList.add('hidden');
  }

  modalCancelClicked(event) {
    event.preventDefault();
    this.approvalBoxTarget.classList.add('hidden')
    this.rejectionBoxTarget.classList.add('hidden')
    this.modalApproveButtonTarget.classList.remove('hidden');
    this.modalRejectButtonTarget.classList.remove('hidden');
    this.modalCloseButtonTarget.classList.remove('hidden');
  }

  detailsOpened(event) {
    event.preventDefault();
    this.rowTarget.classList.add('bg-yellow-200')
  }

  modalCloseClicked(event) {
    event.preventDefault();
    this.rowTarget.classList.remove('bg-yellow-200')
  }
}
