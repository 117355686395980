import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['messageSelect', 'customMessage', 'messagaIdError', 'customMessageError', 'rejectionForm']

  connect() {
    this.showHideCustomMessage()
  }

  showHideCustomMessage() {
    // Clear previous errors
    this.messagaIdErrorTarget.classList.add('hidden')
    this.customMessageErrorTarget.classList.add('hidden')

    if (this.messageSelectTarget.value === '0') {
      this.customMessageTarget.classList.remove('hidden')
    } else {
      this.customMessageTarget.classList.add('hidden')
    }
  }

  validate(event) {
    this.messagaIdErrorTarget.classList.add('hidden')
    this.customMessageErrorTarget.classList.add('hidden')

    if (this.messageSelectTarget.value === '') {
      this.messagaIdErrorTarget.innerText = 'Rejection message must be selected'
      this.messagaIdErrorTarget.classList.remove('hidden')
      return event.preventDefault()
    }

    if (this.messageSelectTarget.value === '0' && this.customMessageTarget.querySelector('input').value.trim().length < 50) {
      this.customMessageErrorTarget.innerText = 'Custom message must be at least 50 characters long'
      this.customMessageErrorTarget.classList.remove('hidden')
      return event.preventDefault()
    }

    this.rejectionFormTarget.submit()
    return event.preventDefault()
  }
}
