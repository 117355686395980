import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class SelectController extends Controller {
  static targets = ["simple", "dynamic", "vessel", "city", "hsCode"];

  connect() {
    if (this.hasSimpleTarget && this.simpleTarget.slim === undefined) {
      this.makeSlimSelect(this.simpleTarget);
    }

    if (this.hasDynamicTarget && this.dynamicTarget.slim === undefined) {
      const base_country = this.element.dataset.country;

      if (!base_country) {
        throw new Error("Missing country data attribute");
      }

      const request_url = `/${base_country}/api/`;
      const element = this.dynamicTarget;
      let url = null;
      let resource = null;

      if (this.hasVesselTarget && element === this.vesselTarget) {
        url = `${request_url}vessels?`;
        resource = "vessel";
      } else if (this.hasCityTarget && element === this.cityTarget) {
        url = `${request_url}cities?`;
        resource = "city";
      } else if (this.hasHsCodeTarget && element === this.hsCodeTarget) {
        url = `${request_url}hs_codes?`;
        resource = "hsCode";
      }

      if (url && resource) {
        this.makeDynamicSelect(element, url, resource);
      }
    }
  }

  makeSlimSelect(element) {
    if (element.style.display !== "none")
      new SlimSelect({
        select: element,
        placeholder: element.dataset.placeholder,
        showSearch: element.dataset.searchable !== "false",
      });
  }

  makeDynamicSelect(element, url, resource) {
    new SlimSelect({
      select: element,
      placeholder: element.dataset.placeholder,
      searchingText: "Searching...",
      allowDeselect: true,
      events: {
        search: async function (search, currentData) {
          if (search.length < 3) {
            return Promise.reject("Need at least 3 characters");
          }

          let req_url = url;
          if (resource === "city")
            req_url = `${req_url}country_id=${element.dataset.countryid}&`;

          return fetch(`${req_url}query=${search}`)
            .then(function (response) {
              return response.json();
            })
            .then(function (json) {
              let data = [];
              for (const item of json)
                data.push({ value: item.id, text: item.name });
              return Promise.resolve(data);
            })
            .catch(function (_) {
              return [];
            });
        },
      },
    });
  }
}
