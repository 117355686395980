import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  validate(event) {
    const target = event.target
    if (target.value === '') return

    if (target.dataset.validation === "uniqueness") {
      const country = target.dataset.country,
          field = target.dataset.field,
          entryID = target.dataset.entryid,
          url = `/${country}/api/validate?entry_id=${entryID}&${field}=${target.value}`
      fetch(url)
          .then(response => response.json())
          .then(json => {
            const message = json["message"]
            if (message === 'There already is an ECTN for this BL') {
              target.closest('div').classList.add('field-has-error')

              // Remove old error message
              if (target.closest('div').querySelector('p') !== null)
                target.closest('div').querySelector('p').remove()
              const error_message = document.createElement('p')
              error_message.innerText = message
              error_message.classList.add('text-red-500', 'text-xs', 'italic')
              target.closest('div').appendChild(error_message)
            } else if (target.closest('div').querySelector('p') !== null) {
              target.closest('div').classList.remove('field-has-error')
              target.closest('div').querySelector('p').remove()
            }
          })
    }
  }
}
