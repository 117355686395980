import {Controller} from "@hotwired/stimulus";

const ACTIVE_ROW_CLASSES = ['bg-blue-200', 'z-50']

export default class extends Controller {
  static targets = ['row', 'actions', 'rowHighlight']

  showHighlight() {
    if (this.actionsTarget.classList.contains('hidden')) {
      this.rowHighlightTarget.classList.remove('hidden')
    }
  }

  hideHighlight() {
    this.rowHighlightTarget.classList.add('hidden')
  }

  showActions() {
    this.rowTarget.classList.add(...ACTIVE_ROW_CLASSES)
    this.actionsTarget.classList.remove('hidden')
    this.hideHighlight()
  }

  hideActions() {
    this.rowTarget.classList.remove(...ACTIVE_ROW_CLASSES)
    this.actionsTarget.classList.add('hidden')
    this.hideHighlight()
  }

  hideActionsIfNotHovered() {
    if (!this.actionsTarget.matches(':hover')) {
      this.hideActions()
      this.hideHighlight()
    }
  }
}
