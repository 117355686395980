import * as ActiveStorage from '@rails/activestorage'
import '@hotwired/stimulus'
import '@hotwired/turbo-rails'
// import '~/channels/index.js'
import '~/controllers/index.js'
import '~/components/index.js'
import "chartkick/chart.js"
import "flag-icons/css/flag-icons.min.css"
import "slim-select/styles";


Turbo.session.drive = false

ActiveStorage.start()

Turbo.setProgressBarDelay(1)

// document.querySelectorAll(`select:not([data-ssid])`).forEach(e => { new SlimSelect({ select: e }) });
