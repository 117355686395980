import CountryBaseController from "@/controllers/country_base_controller";

export default class extends CountryBaseController {
  static targets = [
    // Components
    'selectBox',
    // Person Relations
    'shipperName', 'shipperEmail', 'shipperAddress', 'shipperCity', 'shipperCountry',
  ]

  connect() {
    this.base_country = this.element.dataset.country
    if (this.hasSelectBoxTarget)
      this.selectBoxTargets.forEach(element => this.makeSlimSelect(element))
  }

  async populatePerson(event) {
    const entityType = event.currentTarget.dataset.entitytype
    const entityName = entityType.slice(-3) === 'ies' ? `${entityType.slice(0, -3)}y` : `${entityType.slice(0, -1)}`

    const response = await this.getPersonDataToPopulate(event)

    if (!response.data || response.error) {
      this[`${entityName}EmailTarget`].value = ''
      this[`${entityName}AddressTarget`].value = ''
      this[`${entityName}CityTarget`].value = ''
      if (!(entityName === 'consignee'))
        this[`${entityName}CountryTarget`].slim.setSelected(0)
      return
    }

    const {data: person} = response
    this[`${entityName}EmailTarget`].value = person.email
    this[`${entityName}AddressTarget`].value = person.address
    this[`${entityName}CityTarget`].value = person.city
    if (!(entityName === 'consignee'))
      this[`${entityName}CountryTarget`].slim.setSelected(person['country-id'])
  }
}
