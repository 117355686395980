import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'cons', 'consName', 'consEmail', 'consPhone', 'consPostCode', 'consAddress', 'consCity', 'consCountry',
    'shppr', 'shpprName', 'shpprEmail', 'shpprPhone', 'shpprPostCode', 'shpprAddress', 'shpprCity', 'shpprCountry',
    'ntfy', 'ntfyName', 'ntfyEmail', 'ntfyPhone', 'ntfyPostCode', 'ntfyAddress', 'ntfyCity', 'ntfyCountry',
    'frwdr', 'frwdrName', 'frwdrEmail', 'frwdrPhone', 'frwdrPostCode', 'frwdrAddress', 'frwdrCity', 'frwdrCountry',
    'sameConsigneeCheck', 'sameShipperCheck']

  sameToNotify(event) {
    if (event.currentTarget.checked) {
      let emptyFields = this.emptyFieldsFor('cons')
      // Check any required fields of source entity is empty
      if (emptyFields.length > 0) {
        this.showErrorFor(event.currentTarget, emptyFields[0])
        return event.preventDefault()
      }
      // All required fields are filled
      this.hideErrorFor(event.currentTarget)
      this.populateFieldsWith('cons', 'ntfy')
    } else
      this.clearFields('cons', 'ntfy')
  }

  sameToForwarder(event) {
    if (event.currentTarget.checked) {
      let emptyFields = this.emptyFieldsFor('shppr')
      // Check any required fields of source entity is empty
      if (emptyFields.length > 0) {
        this.showErrorFor(event.currentTarget, emptyFields[0])
        return event.preventDefault()
      }
      // All required fields are filled
      this.hideErrorFor(event.currentTarget)
      this.populateFieldsWith('shppr', 'frwdr')
    } else
      this.clearFields('shppr', 'frwdr')
  }

  populateFieldsWith(source, target) {
    let source_fields = this.personFields(source)
    let target_fields = this.personFields(target)
    target_fields.forEach((field, i) => {
      field instanceof HTMLSelectElement ? field.slim.setSelected(source_fields[i].value) : field.value = source_fields[i].value
    })
    this.disableFields(source)
    this.disableFields(target)
  }

  clearFields(source, target) {
    this.enableFields(source)
    this.enableFields(target)
    this.personFields(target).forEach(field => field.value = "")
  }

  // PRIVATE

  personFields(prefix) {
    let fields = [
      `${prefix}Name`, `${prefix}Email`, `${prefix}Phone`, `${prefix}PostCode`,
      `${prefix}Address`, `${prefix}City`, `${prefix}Country`
    ]
    return fields.map(field => {
      if (this[`has${field.charAt(0).toUpperCase()}${field.slice(1)}Target`])
        return this[`${field}Target`]
    }).filter(el => el)
  }

  emptyFieldsFor(target) {
    return this.personFields(target).filter(elem => elem.value === '' && elem.classList.contains('required'))
  }

  showErrorFor(target, focus) {
    target.closest('div').querySelector('.requirements-error').classList.remove('hidden')
    target.checked = false
    focus.focus()
  }

  hideErrorFor(target) {
    target.closest('div').querySelector('.requirements-error').classList.add('hidden')
  }

  enableFields(target) {
    this.personFields(target).forEach(el => {
      // el instanceof HTMLSelectElement ? el.slim.disable() : el.style.pointerEvents = "auto"
      el.style.pointerEvents = "auto"
      el.classList.remove('bg-gray-200')
    })
  }

  disableFields(target) {
    this.personFields(target).forEach(el => {
      // el instanceof HTMLSelectElement ? el.slim.disable() : el.style.pointerEvents = "none"
      el.style.pointerEvents = "none"
      el.classList.add('bg-gray-200')
    })
  }
}
