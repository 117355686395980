import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['row', 'rowHighlight']

  showHighlight() {
      this.rowHighlightTarget.classList.remove('hidden')
  }

  hideHighlight() {
    this.rowHighlightTarget.classList.add('hidden')
  }
}
