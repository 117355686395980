import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['originSelect', 'originIdError', 'approvalForm']

  validate(event) {
    if (this.originSelectTarget.value === '') {
      this.originIdErrorTarget.innerText = 'Origin must be selected'
      this.originIdErrorTarget.classList.remove('hidden')
      return event.preventDefault()
    }

    this.approvalFormTarget.submit()
    return event.preventDefault()
  }
}
