import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['uploadCountry', 'uploadInfoLabel']

  connect() {
    this.fileNames = {
      "angola": ["Bill of Lading", "Commercial Invoice", "DU Document", "Packing List"],
      "benin": ["Bill of Lading", "Commercial Invoice", "Freight Invoice", "Packing List", "Customs Declaration"],
      "burkina_faso": ["Bill of Lading", "Commercial Invoice", "Freight Invoice"],
      "cameroon": ["Bill of Lading", "Commercial Invoice", "Freight Invoice"],
      "chad": ["Bill of Lading", "Commercial Invoice", "Freight Invoice"],
      "guinea": ["Bill of Lading", "Commercial Invoice", "Freight Invoice", "Export Declaration"],
      "guinea_bissau": ["Bill of Lading", "Commercial Invoice", "Freight Invoice"],
      "ivory_coast": ["Bill of Lading", "Certificate of Origin", "Certificate of Insurance", "Commercial Invoice", "Customs Declaration", "FDI", "Freight Invoice", "Packing List"],
      "liberia": ["Bill of Lading", "Commercial Invoice", "Customs Declaration"],
      "libya": ["Bill of Lading", "Certificate of Origin", "Commercial Invoice", "Customs Declaration", "Freight Invoice", "Statistical Code (ID)"],
      "madagascar": ["Bill of Lading", "Commercial Invoice", "Customs Declaration", "Packing List"],
      "mali": ["Bill of Lading", "Commercial Invoice", "Freight Invoice"],
      "niger": ["Bill of Lading", "Commercial Invoice", "Freight Invoice"],
      "republic_of_congo": ["Bill of Lading", "Commercial Invoice", "Freight Invoice", "GUOT"],
      "senegal": ["Bill of Lading", "Commercial Invoice", "Freight Invoice"],
      "sierra_leone": ["Bill of Lading", "Certificate of Origin", "Commercial Invoice", "Customs Declaration", "Packing List"]
    }
    if (this.uploadCountryTarget.value !== '')
      this.uploadInfo()
  }

  uploadInfo() {
    const country = this.uploadCountryTarget.value.toLowerCase()
    const docs = this.fileNames[country]
    let list = document.createElement('ol')
    docs.forEach(doc_name => {
      let item = document.createElement('li')
      item.innerText = doc_name
      list.appendChild(item)
    })
    this.uploadInfoLabelTarget.innerHTML = "<span class='text-sm text-gray-500 mb-2'>You must upload following documents:</span>"
    this.uploadInfoLabelTarget.appendChild(list)
  }
}
