import CountryBaseController from "@/controllers/country_base_controller";

export default class extends CountryBaseController {
  static targets = [
    // Components
    'selectBox', 'ajaxified', 'shipmentType', 'numberOfVehicles',
    // Country/Ports Population Targets
    'pol',
    // Association Options
    'vessel', 'city',
    // Person Relations
    'companyCity', 'companyCountry',
    'shipperName', 'shipperEmail', 'shipperPhone', 'shipperAddress', 'shipperCity', 'shipperCountry',
    'consigneeName', 'consigneeEmail', 'consigneePhone', 'consigneeAddress', 'consigneeCity',
  ]

  connect() {
    this.base_country = this.element.dataset.country
    if (this.hasSelectBoxTarget)
      this.selectBoxTargets.forEach(element => this.makeSlimSelect(element))
    if (this.hasAjaxifiedTarget) {
      let request_url = `/${this.base_country}/api/`
      this.ajaxifiedTargets.forEach(element => {
        if (element === this.vesselTarget) {
          let url = `${request_url}vessels?`
          this.makeAjaxifiedSelect(element, url, 'vessel')
        } else if (this.cityTargets.includes(element)) {
          let url = `${request_url}cities?`
          this.makeAjaxifiedSelect(element, url, 'city')
        }
      })
    }
  }

  populate(event) {
    const selectBox = event.target,
        target = this[selectBox.dataset.populatetarget]

    target.slim.enable()
    target.setAttribute('data-countryid', selectBox.value)

    if (target.dataset.afterpopulatecityid) {
      target.slim.setData([{text: target.dataset.afterpopulatecityname, value: target.dataset.afterpopulatecityid}])
      target.slim.setSelected(target.dataset.afterpopulatecityid)
      target.removeAttribute('data-afterpopulatecityname')
      target.removeAttribute('data-afterpopulatecityid')
    } else if (target.dataset.entitytype !== 'consignees') {
      target.slim.setData([{text: '', placeholder: true}])
      target.slim.setSelected(0)
    }
  }

  async populatePerson(event) {
    const entityType = event.currentTarget.dataset.entitytype
    const entityName = entityType.slice(-3) === 'ies' ? `${entityType.slice(0, -3)}y` : `${entityType.slice(0, -1)}`

    const response = await this.getPersonDataToPopulate(event)

    if (!response.data || response.error) {
      this[`${entityName}EmailTarget`].value = ''
      this[`${entityName}PhoneTarget`].value = ''
      this[`${entityName}AddressTarget`].value = ''
      if (entityType !== 'consignees') {
        this[`${entityName}CountryTarget`].slim.setSelected(0)
        this[`${entityName}CityTarget`].slim.setData([{text: '', placeholder: true}])
      }
      this[`${entityName}CityTarget`].slim.setSelected(0)
      return
    }

    const {data: person} = response
    this[`${entityName}EmailTarget`].value = person.email
    this[`${entityName}PhoneTarget`].value = person.phone
    this[`${entityName}AddressTarget`].value = person.address
    if (entityType === 'consignees') { // consignee has only city
      this[`${entityName}CityTarget`].slim.setSelected(person['city-id'])
    } else { // other people have both country and city
      this[`${entityName}CityTarget`].setAttribute("data-afterpopulatecityname", person.city)
      this[`${entityName}CityTarget`].setAttribute("data-afterpopulatecityid", person['city-id'])
      this[`${entityName}CountryTarget`].slim.setSelected(person['country-id'])
    }
  }

  showHideNumOfVehicles() {
    if (this.shipmentTypeTarget.value === 'Container') {
      this.numberOfVehiclesTarget.classList.remove('hidden')
    } else {
      this.numberOfVehiclesTarget.classList.add('hidden')
    }
  }
}
