import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'targetElement']

  connect() {
    if (this.targetElementTarget.querySelector("input").value !== '') {
      this.targetElementTarget.classList.remove('hidden')
      this.checkboxTarget.setAttribute('checked', 'checked')
    }
  }

  showHide() {
    this.targetElementTarget.classList.toggle('hidden')
    this.targetElementTarget.querySelector("input").focus()
  }
}
