import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['cargoSize', 'tareValue']

  adjustValue() {
    const DEFAULT_TARES = {
      '20 FT': '2,230',
      '40 FT': '3,780',
    }

    const cargoSizeValue = this.cargoSizeTarget.value
    const tareValue = this.tareValueTarget.value.trim()
    const defaultTareValues = Object.values(DEFAULT_TARES)

    // Set default value only if user didn't enter any custom value
    if (tareValue === '' || defaultTareValues.includes(tareValue)) {
      this.tareValueTarget.value = DEFAULT_TARES[cargoSizeValue]
    }
  }
}
