import CountryBaseController from "@/controllers/country_base_controller";

export default class extends CountryBaseController {
  static targets = [
    // Components
    'selectBox', 'shipmentType', 'volume',
    // Country/City/Ports Population Targets
    'pol',
    // Association Options
    'vessel',
    // Person Relations
    'shipperName', 'shipperEmail', 'shipperPhone', 'shipperAddress', 'shipperCity', 'shipperCountry', 'shipperPostCode',
    'consigneeName', 'consigneeEmail', 'consigneePhone', 'consigneeAddress', 'consigneeCity', 'consigneeCountry', 'consigneePostCode',
    'notifyName', 'notifyEmail', 'notifyPhone', 'notifyAddress', 'notifyCity', 'notifyCountry', 'notifyPostCode',
  ]

  connect() {
    this.base_country = this.element.dataset.country
    if (this.hasSelectBoxTarget)
      this.selectBoxTargets.forEach(element => this.makeSlimSelect(element))
    this.showHideVolume()
  }

  showHideVolume() {
    if (this.shipmentTypeTarget.value === 'Bulk' || this.shipmentTypeTarget.value === 'Genco')
      this.volumeTarget.classList.add('hidden')
    else
      this.volumeTarget.classList.remove('hidden')
  }

  populate(event) {
    const selectBox = event.target,
        target = this[selectBox.dataset.populatetarget]

    target.slim.enable()
    target.setAttribute('data-countryid', selectBox.value)

    if (target.dataset.afterpopulatecityid) {
      target.slim.setData([{text: target.dataset.afterpopulatecityname, value: target.dataset.afterpopulatecityid}])
      target.slim.setSelected(target.dataset.afterpopulatecityid)
      target.removeAttribute('data-afterpopulatecityname')
      target.removeAttribute('data-afterpopulatecityid')
    } else if (target.dataset.entitytype !== 'consignees') {
      target.slim.setData([{text: '', placeholder: true}])
      target.slim.setSelected(0)
    }
  }

  async populatePerson(event) {
    const entityType = event.currentTarget.dataset.entitytype
    const entityName = entityType.slice(-3) === 'ies' ? `${entityType.slice(0, -3)}y` : `${entityType.slice(0, -1)}`

    const response = await this.getPersonDataToPopulate(event)

    if (!response.data || response.error) {
      this[`${entityName}EmailTarget`].value = ''
      this[`${entityName}PhoneTarget`].value = ''
      this[`${entityName}AddressTarget`].value = ''
      this[`${entityName}PostCodeTarget`].value = ''
      this[`${entityName}CountryTarget`].slim.setSelected(0)
      this[`${entityName}CityTarget`].value = ''
      return
    }

    const {data: person} = response
    this[`${entityName}EmailTarget`].value = person.email
    this[`${entityName}PhoneTarget`].value = person.phone
    this[`${entityName}AddressTarget`].value = person.address
    this[`${entityName}PostCodeTarget`].value = person['postal-code']
    this[`${entityName}CountryTarget`].slim.setSelected(person['country-id'])
    this[`${entityName}CityTarget`].value = person.city
  }
}
