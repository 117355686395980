import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class CountryBaseController extends Controller {
  makeSlimSelect(element) {
    if (element.style.display !== 'none')
      new SlimSelect({
        select: element,
        placeholder: element.dataset.placeholder,
        showSearch: element.dataset.searchable !== 'false',
      })
  }

  makeAjaxifiedSelect(element, url, resource) {
    const selectInstance = new SlimSelect({
      select: element,
      placeholder: element.dataset.placeholder,
      searchingText: 'Searching...',
      allowDeselect: true,
      events: {
        search: async (search, currentData) => {
          if (search.length < 3) {
            return Promise.reject('Need at least 3 characters')
          }

          let req_url = url;
          if (resource === 'city')
            req_url = `${req_url}country_id=${element.dataset.countryid}&`

          return fetch(`${req_url}query=${search}`)
            .then(function (response) {
              return response.json()
            })
            .then(function (json) {
              let data = []
              for (const item of json) data.push({ value: item.id, text: item.name })
              selectInstance.setData(data)
              return data;
            })
            .catch(function (_) {
              console.error('An error occurred while fetching data')
              return []
            })
        },
      }
    })
    return selectInstance;
  }

  async populateSimple(event) {
    const selectBox = event.target
    const {
      dataset: { populatetype: populateType, populatetarget: populateTarget, placeholder: oldPlaceholderText },
      value: relationID,
    } = selectBox
    const target = this[populateTarget]

    // Show loading message until ajax request finish
    target.setAttribute('data-placeholder', 'Data is loading. Please wait...')
    target.slim.setData([{ 'placeholder': true, 'text': target.dataset.placeholder }])

    try {
      const requestUrl = `/${this.base_country}/api/countries/${relationID}/${populateType}`
      const response = await fetch(requestUrl)
      const json = await response.json()

      target.innerText = ''
      target.setAttribute('data-placeholder', oldPlaceholderText)
      target.slim.setData([{ 'placeholder': true, 'text': target.dataset.placeholder }])

      let data = []
      for (const item of json.data) data.push({ value: item.id, text: item.attributes.name })
      target.slim.setData(data)
    } catch (error) {
      console.error(error)
      target.setAttribute('data-placeholder', 'An error occurred. Please check your connection.')
      target.slim.setData([{ 'placeholder': true, 'text': target.dataset.placeholder }])
    }
  }

  async getPersonDataToPopulate(event) {
    const entityType = event.currentTarget.dataset.entitytype
    const userID = event.currentTarget.dataset.userid
    const requestUrl = `/${this.base_country}/api/users/${userID}/${entityType}?name=${event.currentTarget.value.trim()}`

    try {
      const response = await fetch(requestUrl)
      const json = await response.json()

      if (!json.data.length) {
        return { data: null }
      }

      const [{ attributes: person }] = json.data
      return { data: person }
    } catch (error) {
      return { error }
    }
  }
}
