import CountryBaseController from "@/controllers/country_base_controller";

export default class extends CountryBaseController {
  static targets = [
    // Components
    'selectBox', 'ajaxified',
    // Shipment related targets
    'goodsBox', 'shipmentType',
    // Country/Ports Population Targets
    'pol',
    // Association Options
    'vessel', 'hsCode',
    // Person Relations
    'shipperName', 'shipperEmail', 'shipperAddress', 'shipperCountry',
    'consigneeName', 'consigneeEmail', 'consigneeAddress', 'consigneeCountry'
  ]

  connect() {
    this.base_country = this.element.dataset.country
    if (this.hasSelectBoxTarget)
      this.selectBoxTargets.forEach(element => this.makeSlimSelect(element))
    if (this.hasAjaxifiedTarget) {
      let request_url = `/${this.base_country}/api/`
      this.ajaxifiedTargets.forEach(element => {
        if (element === this.vesselTarget) {
          let url = `${request_url}vessels?`
          this.makeAjaxifiedSelect(element, url, 'vessel')
        } else if (element === this.hsCodeTarget) {
          let url = `${request_url}hs_codes?`
          this.makeAjaxifiedSelect(element, url, 'hs_code')
        }
      })
    }
    if (this.shipmentTypeTarget.value === '' || this.shipmentTypeTarget.value === 'RoRo') {
      this.goodsBoxTarget.style.display = 'none'
    }
  }

  removeGoodRow(event) {
    event.currentTarget.closest('.assoc-good-row').style.display = 'none'
    event.currentTarget.closest('.assoc-good-row').querySelector('[type="hidden"]').value = "true"
    if (event.currentTarget.dataset.relatedbutton)
      document.querySelector(`.add_fields.${event.currentTarget.dataset.relatedbutton}`)
        .classList.remove('disabled', 'pointer-events-none', 'cursor-not-allowed', 'opacity-50')
    return event.preventDefault()
  }

  async populatePerson(event) {
    const entityType = event.currentTarget.dataset.entitytype
    const entityName = entityType.slice(-3) === 'ies' ? `${entityType.slice(0, -3)}y` : `${entityType.slice(0, -1)}`

    const response = await this.getPersonDataToPopulate(event)

    if (!response.data || response.error) {
      this[`${entityName}EmailTarget`].value = ''
      this[`${entityName}AddressTarget`].value = ''
      this[`${entityName}CountryTarget`].slim.setSelected(0)
      return
    }

    const {data: person} = response
    this[`${entityName}EmailTarget`].value = person.email
    this[`${entityName}AddressTarget`].value = person.address
    this[`${entityName}CountryTarget`].slim.setSelected(person['country-id'])
  }

  showHideGoods(event) {
    if (event.target.value === 'RoRo') { // If shipment type is RoRo, hide goods box
      this.goodsBoxTarget.querySelectorAll('.assoc-good-row').forEach(row => row.remove())
      this.goodsBoxTarget.style.display = 'none'
    } else { // If shipment type is not RoRo, show goods box
      this.goodsBoxTarget.style.display = 'flex'
    }
  }
}
