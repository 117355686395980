import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ["picker", "etd", "eta", "first_reg"]

  connect() {
    this.pickerTargets.forEach(element => this.makeFlatPicker(element))
  }

  makeFlatPicker(element) {
    const dateValue = element.value ? this.parseDate(element.value) : null;
    flatpickr(element, {
      dateFormat: "d-m-Y",
      defaultDate: dateValue || new Date()
    });
  }

  adjustDates(event) {
    const etdVal = this.parseDate(this.etdTarget.value);
    const etaFlatpickr = this.etaTarget._flatpickr;

    let defaultVal;
    if (this.etaTarget.value === '') {
      defaultVal = etdVal;
    } else {
      const etaVal = this.parseDate(this.etaTarget.value);
      if (etaVal <= etdVal) {
        defaultVal = etdVal;
      }
    }

    etdVal.setDate(etdVal.getDate() + 1);

    etaFlatpickr.set({
      defaultDate: defaultVal,
      minDate: etdVal,
    });
  }

  parseDate(dateStr) {
    return new Date(dateStr.split('-').reverse().join('-'));
  }
}
