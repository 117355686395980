import CountryBaseController from "@/controllers/country_base_controller";

export default class extends CountryBaseController {
  static targets = [
    // Components
    'selectBox', 'ajaxified',
    // Country/City/Ports Population Targets
    'pol',
    // Association Options
    'vessel', 'city', 'hsCode',
    // Person Relations
    'shipperName', 'shipperEmail', 'shipperPhone', 'shipperAddress', 'shipperCity', 'shipperCountry',
    'consigneeName', 'consigneeEmail', 'consigneePhone', 'consigneeAddress', 'consigneeCity', 'consigneeCountry', 'nifNo',
    'forwarderName', 'forwarderEmail', 'forwarderPhone', 'forwarderAddress', 'forwarderCity', 'forwarderCountry',
    'notifyName', 'notifyEmail', 'notifyPhone', 'notifyAddress', 'notifyCity', 'notifyCountry'
  ]

  connect() {
    this.base_country = this.element.dataset.country
    if (this.hasSelectBoxTarget)
      this.selectBoxTargets.forEach(element => this.makeSlimSelect(element))
    if (this.hasAjaxifiedTarget) {
      let request_url = `/${this.base_country}/api/`
      this.ajaxifiedTargets.forEach(element => {
        if (element === this.vesselTarget) {
          let url = `${request_url}vessels?`
          this.makeAjaxifiedSelect(element, url, 'vessel')
        } else if (this.cityTargets.includes(element)) {
          let url = `${request_url}cities?`
          this.makeAjaxifiedSelect(element, url, 'city')
        } else if (element === this.hsCodeTarget) {
          let url = `${request_url}hs_codes?`
          this.makeAjaxifiedSelect(element, url, 'hsCode')
        }
      })
    }
  }

  removeGoodRow(event) {
    event.currentTarget.closest('.assoc-good-row').style.display = 'none'
    event.currentTarget.closest('.assoc-good-row').querySelector('[type="hidden"]').value = "true"
    if (event.currentTarget.dataset.relatedbutton)
      document.querySelector(`.add_fields.${event.currentTarget.dataset.relatedbutton}`)
        .classList.remove('disabled', 'pointer-events-none', 'cursor-not-allowed', 'opacity-50')
    return event.preventDefault()
  }

  populate(event) {
    const selectBox = event.target,
      target = this[selectBox.dataset.populatetarget]

    target.slim.enable()
    target.setAttribute('data-countryid', selectBox.value)

    if (target.dataset.afterpopulatecityid) {
      target.slim.setData([{text: target.dataset.afterpopulatecityname, value: target.dataset.afterpopulatecityid}])
      target.slim.setSelected(target.dataset.afterpopulatecityid)
      target.removeAttribute('data-afterpopulatecityname')
      target.removeAttribute('data-afterpopulatecityid')
    } else if (target.dataset.entitytype !== 'consignees') {
      target.slim.setData([{text: '', placeholder: true}])
      target.slim.setSelected(0)
    }
  }

  async populatePerson(event) {
    const entityType = event.currentTarget.dataset.entitytype
    const entityName = entityType.slice(-3) === 'ies' ? `${entityType.slice(0, -3)}y` : `${entityType.slice(0, -1)}`

    const response = await this.getPersonDataToPopulate(event)

    if (!response.data || response.error) {
      this[`${entityName}EmailTarget`].value = ''
      this[`${entityName}PhoneTarget`].value = ''
      this[`${entityName}AddressTarget`].value = ''
      this[`${entityName}CountryTarget`].slim.setSelected(0)
      this[`${entityName}CityTarget`].value = ''
      return
    }

    const {data: person} = response
    this[`${entityName}EmailTarget`].value = person.email
    this[`${entityName}PhoneTarget`].value = person.phone
    this[`${entityName}AddressTarget`].value = person.address
    this[`${entityName}CountryTarget`].slim.setSelected(person['country-id'])
    this[`${entityName}CityTarget`].value = person.city
  }

  // FIXME(egecancitim): This function is not used anywhere. Let's use or remove it.
  nifCheck() {
    const textTargets = [
      this.consigneeNameTarget,
      this.consigneeEmailTarget,
      this.consigneePhoneTarget,
      this.consigneeAddressTarget,
      this.consigneeCityTarget
    ]
    const slimTargets = [
      this.consigneeCountryTarget
    ]

    if (this.nifNoTarget.value.trim() !== '') {
      textTargets.forEach(target => {
        target.setAttribute('disabled', 'disabled')
        target.classList.add('bg-gray-300')
      })

      slimTargets.forEach(target => target.slim.disable())
    } else {
      textTargets.forEach(target => {
        target.removeAttribute('disabled')
        target.classList.remove('bg-gray-300')
      })

      slimTargets.forEach(target => target.slim.enable())
    }
  }
}
