import {Controller} from "@hotwired/stimulus"
import Cleave from "cleave.js";

export default class extends Controller {
  static targets = ['input']

  connect() {
    // NOTE(sitkibagdat): If different kinds of formatting needed in the future, feel free to duplicate mask and customize it.
    const currencyMask = {
      prefix: '',
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
      numeralDecimalScale: 4,
      rawValueTrimPrefix: true
    };

    new Cleave(this.inputTarget, currencyMask);
  }
}
