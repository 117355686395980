import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  static targets = ["type", "box", "header"];

  connect() {
    this.clean_state = true;
    // If type is not selected, hide the box
    this.typeTarget.value !== ""
      ? this.setAssocication()
      : this.boxTarget.classList.add("hidden");
  }

  disconnect() {
    // Remove all association rows
    this.boxTarget
      .querySelectorAll(".assoc-row")
      .forEach((row) => row.remove());
  }

  setAssocication() {
    // Reset "Add ..." buttons disabled style
    document
      .querySelectorAll(".add_fields")
      .forEach((link) =>
        link.classList.remove(
          "disabled",
          "pointer-events-none",
          "cursor-not-allowed",
          "opacity-50"
        )
      );

    // If type is not selected, remove all association rows and buttons completely
    if (this.typeTarget.value === "") {
      this.boxTarget
        .querySelectorAll(".assoc-row")
        .forEach((row) => row.remove());
      this.boxTarget
        .querySelectorAll(".add_fields")
        .forEach((link) => link.classList.add("hidden"));
      return;
    }

    // Set header text
    this.headerTarget.innerText = `${this.typeTarget.value}s`;
    this.boxTarget.classList.remove("hidden");
    // If form is dirty, remove all association rows created before
    if (this.clean_state === false)
      this.boxTarget
        .querySelectorAll(".assoc-row")
        .forEach((row) => row.remove());
    // Mark form is dirty
    this.clean_state = false;
    this.boxTarget.querySelectorAll(".add_fields").forEach((link) => {
      // Find right "Add ..." button and show it, hide the others
      if (
        link.dataset.assoctype.startsWith(this.typeTarget.value.toLowerCase())
      ) {
        link.classList.add("block");
        link.classList.remove("hidden");
      } else {
        link.classList.add("hidden");
        link.classList.remove("block");
      }
    });
  }

  addAssocRow(event) {
    let time = new Date().getTime().toString(),
      regexp = new RegExp(event.currentTarget.dataset.id, "g");
    // Insert association row html code into view
    event.currentTarget.insertAdjacentHTML(
      "beforebegin",
      event.currentTarget.dataset.fields.replace(regexp, time)
    );

    // Find currently created select boxes and sytle them
    document.querySelectorAll(".assoc-row").forEach((row) => {
      row.querySelectorAll("select").forEach((element) => {
        if (element.style.display !== "none")
          new SlimSelect({
            select: element,
            placeholder: element.dataset.placeholder,
            showSearch: element.dataset.searchable !== "false",
          });
      });
    });

    if (
      document.querySelectorAll(".assoc-row").length === 1 &&
      event.currentTarget.dataset.multiple === "false"
    ) {
      event.currentTarget.classList.add(
        "disabled",
        "pointer-events-none",
        "cursor-not-allowed",
        "opacity-50"
      );
    }

    return event.preventDefault();
  }

  removeAssocRow(event) {
    event.currentTarget.closest(".assoc-row").style.display = "none";
    event.currentTarget
      .closest(".assoc-row")
      .querySelector('[type="hidden"]').value = "true";
    if (event.currentTarget.dataset.relatedbutton)
      document
        .querySelector(
          `.add_fields.${event.currentTarget.dataset.relatedbutton}`
        )
        .classList.remove(
          "disabled",
          "pointer-events-none",
          "cursor-not-allowed",
          "opacity-50"
        );
    return event.preventDefault();
  }
}
