import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["status"]
  static values = { url: String }

  connect() {
    console.log('MelisaStatusController connected');
    this.fetchMelisaStatus();
    this.startRefreshing();
  }

  disconnect() {
    this.stopRefreshing();
  }

  fetchMelisaStatus() {
    fetch(this.urlValue, { headers: { 'Accept': 'text/html' } })
      .then(response => response.text())
      .then(html => {
        this.statusTarget.innerHTML = html;
      })
      .catch(error => {
        console.error('Error fetching Melisa status:', error);
        this.statusTarget.innerHTML = '<span class="status-dot bg-gray-500"></span><strong>Unknown</strong>';
      });
  }

  startRefreshing() {
    this.refreshInterval = setInterval(() => {
      this.fetchMelisaStatus();
    }, 300000); // 300,000 milliseconds = 5 minutes
  }

  stopRefreshing() {
    clearInterval(this.refreshInterval);
  }
}