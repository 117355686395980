import CountryBaseController from "@/controllers/country_base_controller";

export default class extends CountryBaseController {
  static targets = [
    // Components
    'selectBox', 'ajaxified', 'shipmentMethod', 'shipmentType',
    // Country/Ports Population Targets
    'pol', 'pod',
    // Association Options
    'hsCode',
    // Person Relations
    'shipperAccountNumber', 'shipperName', 'shipperEmail', 'shipperAddress',
    'consigneeAccountNumber', 'consigneeName', 'consigneeEmail', 'consigneeAddress',
  ]

  connect() {
    this.base_country = this.element.dataset.country
    if (this.hasSelectBoxTarget)
      this.selectBoxTargets.forEach(element => this.makeSlimSelect(element))
    if (this.hasAjaxifiedTarget) {
      let request_url = `/${this.base_country}/api/`
      this.ajaxifiedTargets.forEach(element => {
        if (element === this.hsCodeTarget) {
          let url = `${request_url}hs_codes?`
          this.makeAjaxifiedSelect(element, url, 'hsCode')
        }
      })
    }
    if (!this.shipmentMethodTarget.getAttribute("disabled"))
      this.showHideShipmentType()
  }

  removeItemRow(event) {
    event.currentTarget.closest('.assoc-item-row').style.display = 'none'
    event.currentTarget.closest('.assoc-item-row').querySelector('[type="hidden"]').value = "true"
    if (event.currentTarget.dataset.relatedbutton)
      document.querySelector(`.add_fields.${event.currentTarget.dataset.relatedbutton}`)
          .classList.remove('disabled', 'pointer-events-none', 'cursor-not-allowed', 'opacity-50')
    return event.preventDefault()
  }


  showHideShipmentType() {
    if (this.shipmentMethodTarget.value === 'SEA') {
      this.shipmentTypeTarget.classList.remove('hidden')
      this.shipmentTypeTarget.querySelector('select').slim.setSelected(0)
    }
    else {
      document.querySelectorAll('.assoc-row').forEach(row => row.remove())
      document.getElementById('cargo-box').classList.add('hidden')
      this.shipmentTypeTarget.classList.add('hidden')
    }
  }

  async populatePerson(event) {
    const entityType = event.currentTarget.dataset.entitytype
    const entityName = entityType.slice(-3) === 'ies' ? `${entityType.slice(0, -3)}y` : `${entityType.slice(0, -1)}`

    const response = await this.getPersonDataToPopulate(event)

    if (!response.data || response.error) {
      this[`${entityName}EmailTarget`].value = ''
      this[`${entityName}AddressTarget`].value = ''
      this[`${entityName}AccountNumberTarget`].value = ''
      return
    }

    const {data: person} = response
    this[`${entityName}EmailTarget`].value = person.email
    this[`${entityName}AddressTarget`].value = person.address
    this[`${entityName}AccountNumberTarget`].value = person['account-number']
  }
}
